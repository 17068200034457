<template>
  <BOverlay
    :show="showOverlay"
    spinner-variant="primary"
    variant="semi-dark"
  >
      <!-- hover -->
    <BTable
      small
      hover
      noCollapse
      bordered
      responsive
      striped
      sticky-header
      class="mb-2 rounded"
      style="overflow-x:scroll !imporant; min-height: 605px !important;"
      :fields="fields"
      :items="reunion.alumnos"
    >
      <!-- col: ORDEN -->
      <template
        #cell()="data"
      >
        <!-- col: ORDEN -->
        <div
          v-if="data.field.key == 'orden'"
        >
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.orden.toString()"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="false"
          />
        </div>

        <!-- col: ESTUDIANTE -->
        <div
          v-if="data.field.key == 'nombre_completo'"
        >
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.nombre_completo"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="true"
          />
        </div>

        <!-- col: ASISTENCIA -->
        <div
          v-if="data.field.key == 'asistencia'"
          style="min-width: 100px !important;"
        >
          <BRow>
            <BCol
              cols="1"
              sm="1"
              md="1"
              lg="1"
              class="mr-0"
            >
              <BFormCheckbox
                v-show="data.item.asistencia && data.item.fecha_retiro === null"
                :id="data.id_persona_rol_alumno"
                v-model="data.item.asistencia"
                title="Presente"

                class="text-center custom-control-success cursor-pointer"
                @change="chkAsistencia(data.item.id_persona_rol_alumno, false)"
              />
              <span
                v-show="!data.item.asistencia || data.item.fecha_retiro !== null"
                @click="data.item.fecha_retiro !== null
                  ? ''
                  : chkAsistencia(data.item.id_persona_rol_alumno, true)"
                class="float-left ml-0 pl-0"
                title="Ausente"
              >
                <feather-icon
                  icon="XSquareIcon"
                  size="22"
                  title="Ausente"
                  :class="data.item.fecha_retiro !== null
                    ? 'text-secondary text-center mr-50 ml-0 mt-0 mb-0 pl-0 cursor-pointer'
                    : 'text-danger text-center mr-50 ml-0 mt-0 mb-0 pl-0 cursor-pointer'"
                  style="margin-left: -2px !important;"
                  :disabled="data.item.fecha_retiro !== null"
                />
              </span>
            </BCol>
            <!-- TIPO ASISTENCIA -->
            <BCol
              cols="9"
              lg="9"
              md="9"
              sm="9"
              class="ml-0"
            >
              <div
                v-show="data.item.asistencia && data.item.fecha_retiro === null"
              >
                Presente
              </div>
              <div
                v-show="!data.item.asistencia || data.item.fecha_retiro !== null"
              >
                Ausente
              </div>
            </BCol>
          </BRow>
        </div>

        <!-- col: FIRMA -->
        <!-- <div
          v-if="data.field.key == 'firma'"
          style="min-width: 150px !important;"
        >
          <div
            v-if="data.item.fecha_retiro === null"
          >
            <BButton size="sm" variant="success">Adjuntar Firma</BButton>
          </div>
        </div> -->
      </template>

      <!-- <template #cell(orden)="data">
        <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.orden.toString()"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="false"
          />
      </template> -->

      <!-- col: NOMBRE_COMPLETO -->
      <!-- <template #cell(nombre_completo)="data">
        <estudiante-retirado
          :rut="data.item.rut"
          :data.sync="data.item.nombre_completo"
          :fecha_retiro.sync="data.item.fecha_retiro"
          :fecha_ingreso.sync="data.item.fecha_ingreso"
          :genero.sync="data.item.genero"
          :mayus="true"
        />
      </template> -->

      <!-- col: ASISTENCIAS -->
      <!-- <template #cell(asistencia)="data">

      </template> -->

      <!-- col: FIRMAS -->
      <!-- <template #cell(firma)="data">
        <div
          v-if="data.item.fecha_retiro === null"
        >
          <BButton size="sm" variant="success">Adjuntar Firma</BButton>
        </div>
      </template> -->

    </BTable>
  </BOverlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BButton
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { integer } from '@vuelidate/validators'

// MIXINS
import { formatos } from '@/mixins/formatos'

// COMPONENTES RECICLADOS
import estudianteRetirado from '../../components/List/estudianteRetirado.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BButton,
    BOverlay,
    BCard,

    // COMPONENTES RECICLADOS
    estudianteRetirado
  },
  mixins: [formatos],
  data() {
    return {
      showOverlay: false,
      // items: [],
      fields: [
        {
          key: 'orden',
          label: '#',
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '0% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          label: 'Estudiantes',
          stickyColumn: false,
          thStyle: {
            width: '60% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'asistencia',
          label: 'Asistencias',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '15% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'firma',
        //   label: 'Firmas',
        //   tdClass: 'text-center',
        //   thStyle: {
        //     width: '10% !important',
        //     'text-align': 'center',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
    }
  },
  props: {
    id_curso: {
      type: Number,
      required: false,
    },
    reunion: {
      type: Object,
      required: false,
    },
    alumnos: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ getAlumnos: 'personas/getAlumnos', }),
  },
  watch: {
    id_curso(id_curso) {
      if (typeof this.reunion.id === 'undefined') {
        this.cargaAlumnos(id_curso)
      }
    },
  },
  mounted() {
    if (this.alumnos.length > 0) { // EDITAR
      this.setAsistencias(this.alumnos)
    } else { // CREAR
      this.cargaAlumnos(this.id_curso)
    }
  },
  methods: {
    ...mapActions({
      fetchAlumnos: 'personas/fetchAlumnos',
    }),
    chkAsistencia(id_persona_rol_alumno, asistencia) {
      let alumno = this.reunion.alumnos.find(i => i.id_persona_rol_alumno === id_persona_rol_alumno)
      alumno.asistencia = asistencia
    },
    cargaAlumnos(id_curso) {
      this.showOverlay = true

      this.fetchAlumnos(id_curso).then(() => {
        this.setAsistencias(this.getAlumnos)
      })
    },
    setAsistencias(estudiantes) {
      this.reunion.alumnos = []
      estudiantes.forEach(alumno => {
        const rut = parseInt(`${alumno.rut}${alumno.dv}`)
        const nombre_completo = `${alumno.primer_apellido} ${alumno.segundo_apellido} ${alumno.nombre} ${alumno.segundo_nombre}`
        let asistencia = true
        if (typeof alumno.asistencia !== 'undefined') {
          asistencia = alumno.asistencia === 1 ? true : false
        }
        let id_asistencia = typeof alumno.id_asistencia !== 'undefined'
          ? alumno.id_asistencia
          : null
        this.reunion.alumnos.push({
          id_asistencia,
          id_persona_rol_alumno: alumno.id_persona_rol_alumno,
          orden: alumno.orden,
          rut,
          nombre_completo,
          asistencia,
          firma: false,
          fecha_retiro: alumno.fecha_retiro,
          fecha_ingreso: alumno.fecha_ingreso,
          genero: alumno.genero,
        })
      })
      this.showOverlay = false
    },

  },
}
</script>

<style lang="scss">
  .cursor-pointer {
    cursor: pointer;
  }
</style>